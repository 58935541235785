<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>尺寸对照列表</span>
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="add"
          style="float: right; position: relative; bottom: 10px"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="query(1)"
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          icon="el-icon-search"
          >查询</el-button
        >
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID"  width="100px"></el-table-column>
        <el-table-column prop="title" label="标题" width="200px"></el-table-column>
        <el-table-column label="尺寸信息">
          <template slot-scope="scope">
            <el-table
              border
              size="mini"
              :data="scope.row.options.x"
              style="width: 100%; margin-bottom: 10px"
            >
              <el-table-column>
                <template slot-scope="scope1">
                  <strong> {{ scope1.row }}</strong>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(y, index) in scope.row.options.y"
                :key="index"
                :label="y"
              >
                <template slot-scope="scope1">
                  {{ scope.row.options.d[y + "|" + scope1.row] }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="phone" width="100px" label="全局默认">
          <template slot-scope="scope">
            <el-switch
              @change="chStatus(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="edit(scope.row)"
              >修改</el-button
            >
            <p style="margin-top:20px;">
            <el-button size="small" type="danger" @click="remove(scope.row.id)"
              >删除</el-button
            >
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog
      center
      fullscreen
      :title="title + '尺寸'"
      :visible.sync="dialogFormVisible"
    >
      <el-card v-if="dialogFormVisible">
        <el-form :model="form" ref="form">
          <el-card>
            <div slot="header">
              <span>基础设置</span>
            </div>
            <el-form-item
              label="标题"
              prop="title"
              :rules="[{ required: true, message: '标题不能为空' }]"
            >
              <el-input
                style="width: 500px"
                v-model="form.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-card>

          <el-card style="margin-top: 10px">
            <div slot="header" style="display: flex; align-items: center">
              <span>尺寸对照</span>

              <el-row style="flex: 1">
                <el-col :span="12">
                  <el-form-item label-width="120px" label="尺码" style="margin-bottom: 0">
                    <el-select
                      style="width: 100%"
                      v-model="form.options.x"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                    >
                      <el-option
                        v-for="item in xs"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label-width="120px" label="国家" style="margin-bottom: 0">
                    <el-select
                      style="width: 100%"
                      v-model="form.options.y"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                    >
                      <el-option
                        v-for="item in ys"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-table :data="form.options.x" style="width: 100%; margin-bottom: 10px">
              <el-table-column>
                <template slot-scope="scope">
                  <strong> {{ scope.row }}</strong>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(y, index) in form.options.y"
                :key="index"
                :label="y"
              >
                <template slot-scope="scope">
                  <el-input v-model="form.options.d[y + '|' + scope.row]"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      form: {},
      dialogFormVisible: false,
      title: "",
      xs: ["XXXS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "4XL"],
      ys: ["CN", "FR", "IT", "US", "UK"],
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },

    chStatus(info) {
      this.$http
        .post("/admin/size/save", { id: info.id, status: info.status })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            this.query()
          }
        });
    },

    edit(row) {
      this.title = "修改";
      this.form = JSON.parse(JSON.stringify(row));
      if (!this.form.options) {
        this.form.options = {
          x: [],
          y: [],
          d: {},
        };
      }
      this.dialogFormVisible = true;
    },

    add() {
      this.form = { options: { x: [], y: [], d: {} } };
      this.title = "添加";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/size/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/size/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/size/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
